
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import '../BaseModal.css';
import { capitalizeFirstLetter, formateDateTime } from '../../../utils/Utils';
import { updateClientProfile } from "../../../services/common/CommonServices";
import { toast } from 'react-toastify';

export const ClientDetailModal = ({ show, onHide, clientDetail }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [editedFields, setEditedFields] = useState({});
    const [initialValues, setInitialValues] = useState({});

    // Initialize the editedFields when clientDetail changes
    useEffect(() => {
        if (clientDetail) {
            setInitialValues({
                sec_email: clientDetail.sec_email,
                familyName: clientDetail.familyName,
                name: clientDetail.name,
                nickName: clientDetail.nickName,
                addressApt: clientDetail.addressApt,
                phoneNumber: clientDetail.phoneNumber,
                gender: clientDetail.gender,
                age: clientDetail.age,
                addLocation: clientDetail.addLocation,
                addTown: clientDetail.addTown,
                addNotes: clientDetail.addNotes,
                addPostCode: clientDetail.addPostCode,
                addCountry: clientDetail.addCountry,
            });
        }
    }, [clientDetail]);

    const handleEditClick = async () => {
        if (isEditable) {
            // Only send fields that have been changed
            const updatedFields = Object.keys(editedFields).reduce((acc, key) => {
                console.log("acc[key]", acc[key]);
                console.log("editedFields[key]", editedFields[key]);
                console.log("initialValues[key]", initialValues[key])
                if (editedFields[key] !== initialValues[key]) {
                    acc[key] = editedFields[key];
                }
                return acc;
            }, {});

            // If there are any changes, proceed with the update
            if (Object.keys(updatedFields).length > 0 && clientDetail?.id) {
                try {
                    const payload = {
                        ...updatedFields,
                        clientId: clientDetail.id,
                    };

                    const response = await updateClientProfile(payload);
                    console.log('Profile updated:', response);

                    if (response?.status === true) {
                        toast(response?.message, { type: 'success' });
                    } else {
                        toast(response?.message, { type: 'error' });
                    }
                } catch (error) {
                    console.error('Error updating profile:', error);
                }
            } else {
                console.error('No fields to update or Client ID is missing');
            }
        }

        setIsEditable((prevState) => !prevState);
    };
    const handleFieldChange = (field, value) => {
        if (value !== initialValues[field]) {
            setEditedFields(prevState => ({
                ...prevState,
                [field]: value
            }));
        }
    };
    
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className="modal-header">
                <Modal.Title id="contained-modal-title-vcenter">
                    Client Information
                </Modal.Title>
                <button
                    className="btn btn-secondary me-2 "
                    onClick={handleEditClick}
                    style={{
                        fontSize: '16px',
                        width: '20%',
                        borderRadius: '50px',
                        backgroundColor: '#F4F7FE',
                        color: 'black',
                        border: 'none',
                        fontWeight: '450'
                    }}
                >
                    {isEditable ? 'Save Changes' : 'Edit Profile'}
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="common-card client-info-box">
                    <div className="client-detail-wrapper">
                        <div className="item-wrapper">
                            <p className="key">Client ID</p>
                            <div className="overlap-3" style={{
                                background: isEditable && "rgb(235 235 228 / 40%)",
                                pointerEvents: isEditable && 'none',
                            }}>
                                <p className="span-wrapper">{clientDetail?.id}</p>
                            </div>
                        </div>
                        <div className="item-wrapper">
                            <p className="key">Date of acceptance</p>
                            <div className="overlap-3" style={{
                                background: isEditable && "rgb(235 235 228 / 40%)",
                                pointerEvents: isEditable && 'none',
                            }}>
                                <p className="span-wrapper">{formateDateTime(clientDetail?.statusUpdateDate)}</p>
                            </div>
                        </div>
                        {/* Editable Email ID */}
                        <div className="item-wrapper">
                            <p className="key">Email ID</p>
                            <div className="overlap-3">
                                <p
                                    className="span-wrapper"
                                    contentEditable={isEditable}
                                    suppressContentEditableWarning
                                    onBlur={(e) => handleFieldChange('sec_email', e.target.innerText)}
                                >
                                    {editedFields.sec_email || clientDetail?.primaryEmail}
                                </p>
                            </div>
                        </div>
                        {/* Editable Phone Number */}
                        <div className="item-wrapper">
                            <p className="key">Phone Number</p>
                            <div className="overlap-3">
                                <p
                                    className="span-wrapper"
                                    contentEditable={isEditable}
                                    suppressContentEditableWarning
                                    onBlur={(e) => handleFieldChange('phoneNumber', e.target.innerText)}
                                >
                                    {editedFields.phoneNumber || clientDetail?.phoneNumber}
                                </p>
                            </div>
                        </div>
                        {/* Editable Family Name */}
                        <div className="item-wrapper">
                            <p className="key">Family Name</p>
                            <div className="overlap-3">
                                <p
                                    className="span-wrapper"
                                    contentEditable={isEditable}
                                    suppressContentEditableWarning
                                    onBlur={(e) => handleFieldChange('familyName', e.target.innerText)}
                                >
                                    {editedFields.familyName || clientDetail?.familyName}
                                </p>
                            </div>
                        </div>
                        {/* Editable Full Name */}
                        <div className="item-wrapper">
                            <p className="key">Full Name</p>
                            <div className="overlap-3">
                                <p
                                    className="span-wrapper"
                                    contentEditable={isEditable}
                                    suppressContentEditableWarning
                                    onBlur={(e) => handleFieldChange('name', e.target.innerText)}
                                >
                                    {editedFields.name || clientDetail?.name}
                                </p>
                            </div>
                        </div>
                        {/* Editable Nick Name */}
                        <div className="item-wrapper">
                            <p className="key">Nick Name</p>
                            <div className="overlap-3">
                                <p
                                    className="span-wrapper"
                                    contentEditable={isEditable}
                                    suppressContentEditableWarning
                                    onBlur={(e) => handleFieldChange('nickName', e.target.innerText)}
                                >
                                    {editedFields.nickName || clientDetail?.nickName}
                                </p>
                            </div>
                        </div>
                        {/* Editable Gender */}
                        <div className="item-wrapper">
                            <div className="sub-item-wrapper">
                                <div className="sub-item-wrapper-1">
                                    <p className="key">Gender</p>
                                    <div className="overlap-3">
                                        <p
                                            className="span-wrapper"
                                            contentEditable={isEditable}
                                            suppressContentEditableWarning
                                            onBlur={(e) => handleFieldChange('gender', e.target.innerText)}
                                        >
                                            {capitalizeFirstLetter(editedFields.gender || clientDetail?.gender)}
                                        </p>
                                    </div>
                                </div>
                                <div className="sub-item-wrapper-2">
                                    <p className="key">Age</p>
                                    <div className="overlap-3">
                                        <p
                                            className="span-wrapper"
                                            contentEditable={isEditable}
                                            suppressContentEditableWarning
                                            onBlur={(e) => handleFieldChange('age', e.target.innerText)}
                                        >
                                            {editedFields.age || clientDetail?.age}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Editable Address */}
                        <div className="item-wrapper address-wrapper">
                            <p className="key">Address</p>
                            <div className="address-overlap-3-wrapper">
                                {['addressApt', 'addLocation', 'addPostCode', 'addCountry', 'addTown', 'addNotes'].map((field) => (
                                    <div className="overlap-3" key={field}>
                                        <p
                                            className="span-wrapper"
                                            contentEditable={isEditable}
                                            suppressContentEditableWarning
                                            onBlur={(e) => handleFieldChange(field, e.target.innerText)}
                                        >
                                            {editedFields[field] || clientDetail?.[field]}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer"></Modal.Footer>
        </Modal>
    );
};
