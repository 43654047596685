import React, { useEffect, useState } from "react";
import addFeedbackImg from '../../../assets/images/plusSign.png';
import blackUpArrow from "../../../assets/images/blackUpArrow.png";
import { getAdminContactInfo, getAllTestimonials, getHealthCategory } from "../../../services/common/CommonServices";
import { toast } from 'react-toastify';
import editImage from '../../../assets/images/edit.svg'
import { BeatComponentOverlayLoader } from "../../../components/loaders/BeatComponentOverlayLoader";
import { somethingWrongText } from "../../../constants/Variables";
import { AddTestimonialModal } from "../../../components/modals/addTestimonialModal/AddTestimonialModal";
import { AddEditHealthCategoryModal } from "../../../components/modals/addEditHealtCategory/AddEditHealthCategoryModal";
import { AddEditAdminContactInfo } from "../../../components/modals/addEditAdminContactInfo/addEditAdminContactInfo";
import '../../common/clientDetailPage/ClientDetailPage.css'
import '../../../styles/ManageApp.css'
import { AddEditSubHealthCategory } from "../../../components/modals/addEditSubHealthCategory/AddEditSubHealthCategory";



export const ManageApplication = () => {
    //concact info *************************************
    const [contactInfo, setContactInfo] = useState({
        data: [],
        loading: false,
        triggered: false,
    })
    const [selectedId, setSelectedId] = useState('');
    const [showEditContactInfo, setShowEditcontactInfo] = useState(false)
    const [editableContactInfo, setEditableContactInfo] = useState({})

    // healthCategory states***********************************
    const [healthCategory, setHealthCategory] = useState({
        data: [],
        loading: false,
        triggered: false,
    })
    const [showAddHeathCategory, setShowAddHealthCategory] = useState(false)
    const [editableHealthCategory, setEditableHeathCategory] = useState({})
    const [showAddSubHealthCategory, setShowAddSubHealthCategory] = useState(false)
    const [editSubHealtCategroy, setEditSubHealthCategory] = useState({})
    //testimonials states************************************
    const [testimonials, setTestimonials] = useState({
        data: [],
        loading: false,
        triggered: false,
    })
    const [showAddTestimonialModal, setShowAddTestimonialModal] = useState(false)
    const [testimonialItemId, setTestimonialItemId] = useState({})

    /***********hooks**********/

    useEffect(() => {
        getTestimonials()
    }, [testimonials.triggered])

    useEffect(() => {
        getAllHealthCategory();
    }, [healthCategory.triggered])


    useEffect(() => {
        getAlldminContactInfo();
    }, [contactInfo.triggered])


    //function related to admin info ****************************

    const getAlldminContactInfo = () => {
        setContactInfo({ ...contactInfo, loading: true });
        const contactInfoResponse = getAdminContactInfo()
        contactInfoResponse.then((data) => {
            // console.log(data, "its quix response")
            setContactInfo({ ...contactInfo, loading: false });
            if (data?.status) {
                setContactInfo({ ...contactInfo, data: data?.ContactInfo });
            }
            else {
                toast(somethingWrongText, { type: 'error' });
            }
        })
            .catch((error) => {
                setContactInfo({ ...contactInfo, loading: false });
                toast(somethingWrongText, { type: 'error' });
            })
    }


    //functions related to health Category********************
    const getAllHealthCategory = () => {
        setHealthCategory({ ...healthCategory, loading: true });
        const healthCategoryResponse = getHealthCategory()
        healthCategoryResponse.then((data) => {
            // console.log(data, "its quix response")
            setHealthCategory({ ...healthCategory, loading: false });
            if (data?.status) {
                setHealthCategory({ ...healthCategory, data: data?.data });
            }
            else {
                toast(somethingWrongText, { type: 'error' });
            }
        })
            .catch((error) => {
                setHealthCategory({ ...healthCategory, loading: false });
                toast(somethingWrongText, { type: 'error' });
            })

    }

    const handleAddCategoryEditButton = (item) => {
        setEditableHeathCategory(item)
        setShowAddHealthCategory(true)
    }
    const handleSubAddCategoryEditButton = (item) => {
        setEditSubHealthCategory(item);
        setShowAddSubHealthCategory(true)
    }

    //function realated to testimonials*************************
    const getTestimonials = () => {
        setTestimonials({ ...testimonials, loading: true });
        const testimonialsResponse = getAllTestimonials()
        testimonialsResponse.then((data) => {
            // console.log(data, "its quix response")
            setTestimonials({ ...testimonials, loading: false });
            if (data?.status) {
                setTestimonials({ ...testimonials, data: data?.Testimonials });
            }
            else {
                toast(somethingWrongText, { type: 'error' });
            }
        })
            .catch((error) => {
                setTestimonials({ ...testimonials, loading: false });
                toast(somethingWrongText, { type: 'error' });
            })

    }
    const openAccordion = (code) => {
        if (code == selectedId) {
            setSelectedId('')
        } else {
            setSelectedId(code)
        }
    }
    const handleTestimonialEditButton = (item) => {
        setTestimonialItemId(item)
        setShowAddTestimonialModal(true);
    }


    return (
        <>
            <div className="user-info-container manage-app-wrapper">
                <div className="user-info-subcontainer">
                    <div className="first-box mb-0">
                        <div className="common-card client-intro-box position-relative custom-box-style ps-0 pe-0">
                            <div className="title d-flex justify-content-between padding18">
                                <p className="text-wrapper-1">
                                    Contact information
                                </p>
                                <button
                                    className='addfeedback-button'
                                    onClick={() => {
                                        setEditableContactInfo(contactInfo?.data[0])
                                        setShowEditcontactInfo(true)
                                    }
                                    }
                                >
                                    <img src={editImage}
                                        alt="edit" />
                                </button>
                            </div>
                            <div className="client-detail-wrapper internal-scroll admin-content-data-container mt-0 mt-2">
                                <div className="item-wrapper">
                                    <p className="key">
                                        Name
                                    </p>
                                    <div className="overlap-3">
                                        <p className="span-wrapper">
                                            {contactInfo?.data[0]?.name}
                                        </p>
                                    </div>
                                </div>
                                <div className="item-wrapper">
                                    <p className="key">
                                        Phone Number
                                    </p>
                                    <div className="overlap-3">
                                        <p className="span-wrapper">
                                            {contactInfo?.data[0]?.contact}
                                        </p>
                                    </div>
                                </div>
                                <div className="item-wrapper">
                                    <p className="key">
                                        Email ID
                                    </p>
                                    <div className="overlap-3">
                                        <p className="span-wrapper">
                                            {contactInfo?.data[0]?.email}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {contactInfo.loading &&
                                <BeatComponentOverlayLoader />
                            }
                        </div>
                    </div>
                    <div className="second-box mb-0">
                        <div className="common-card client-health-box  position-relative custom-box-style ps-0 pe-0 d-flex flex-column">
                            <div className="content-header">
                                <div className="title d-flex justify-content-between">
                                    <p className="text-wrapper-1">
                                        Manage Health Category
                                    </p>
                                    <button
                                        className='addfeedback-button'
                                        onClick={() => setShowAddHealthCategory(true)}
                                    >
                                        <img src={addFeedbackImg}
                                            alt="add" />
                                    </button>
                                </div>
                                <div className="t-head d-flex justify-content-between pt-3 pb-3">
                                    <p>
                                        Category name
                                    </p>
                                    <p>
                                        Category code
                                    </p>
                                </div>
                            </div>
                            <div className="content-data-container health-cate-data-container internal-scroll pt-3">
                                {healthCategory?.data?.map((item, index) => {
                                    return (
                                        <>
                                            <div className="t-body d-flex justify-content-between position-relative" style={{
                                                marginBottom: selectedId == item.categoryCode ? '0px' : '17px', background: '#f1f1f1',
                                                padding: '10px'
                                            }} id={item.categoryCode} key={index}>
                                                <p style={{ margin: '0px !important' }}>
                                                    {item?.name}
                                                </p>
                                                <p>
                                                    {item?.categoryCode}
                                                </p>
                                                <img src={editImage} className="edit-image-absolute" style={{ right: '45px', top: '12px' }}
                                                    alt="edit" onClick={() => handleAddCategoryEditButton(item)} />
                                                <img src={addFeedbackImg} onClick={() => handleSubAddCategoryEditButton(item)} style={{
                                                    width: '18px', height: '18px', position: 'absolute', right: '24px',
                                                    top: '12px'
                                                }}
                                                    alt="add" />
                                                <img src={blackUpArrow} style={{ transform: selectedId == item.categoryCode ? 'rotate(360deg)' : '' }} onClick={() => openAccordion(item.categoryCode)} className="down-image-absolute"
                                                    alt="edit" />
                                            </div>
                                            <div >
                                                {
                                                    selectedId != item.categoryCode ?
                                                        ''
                                                        :
                                                        item?.subCategory.length == 0 ?
                                                            <div style={{ padding: '10px' }}>No Items</div> :
                                                            item?.subCategory?.map((item2, index2) => {
                                                                return (

                                                                    <>
                                                                        <div style={{ padding: '10px' }} className="  d-flex justify-content-between position-relative">
                                                                            <div className="subCategoryHeading">
                                                                                {item2?.name}
                                                                            </div>
                                                                            <div className="code-icon-container d-flex justify-content-end  " style={{ marginRight: '23px' }}>
                                                                                <div>
                                                                                    {item2.subCategorycode}
                                                                                </div>
                                                                                <img src={editImage} className=" " style={{ right: 0, marginLeft: '9px' }}
                                                                                    alt="edit" onClick={() => handleSubAddCategoryEditButton(item2)} />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                }
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            {healthCategory.loading &&
                                <BeatComponentOverlayLoader />
                            }
                        </div>
                    </div>
                    <div className="third-box mb-0">
                        <div className="common-card client-history-box  position-relative custom-box-style pe-0 ps-0 d-flex flex-column"
                        >
                            <div className="title d-flex justify-content-between padding18">
                                <p className="text-wrapper-1">
                                    Manage Testimonials
                                </p>
                                <button
                                    className='addfeedback-button'
                                    onClick={() => setShowAddTestimonialModal(true)}
                                >
                                    <img src={addFeedbackImg}
                                        alt="add" />
                                </button>
                            </div>
                            <div className="internal-scroll health-cate-data-container ">
                                {testimonials?.data?.map((item, index) => {
                                    return (
                                        <>
                                            <div className="active-question-wrapper testimonial-wrapper" key={index}>
                                                <div className="d-flex justify-content-between">
                                                    <p className="question-title">
                                                        {item?.title}
                                                    </p>
                                                    <img src={editImage}
                                                        alt="edit" onClick={() => handleTestimonialEditButton(item)} />
                                                </div>
                                                <p className="text">
                                                    {item?.description}
                                                </p>
                                                <a className="linkStyle" href={item?.link} target="_blank">
                                                    {item?.link}
                                                </a>
                                            </div>

                                        </>
                                    )
                                })}
                                {testimonials.loading &&
                                    <BeatComponentOverlayLoader />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showEditContactInfo &&
                <AddEditAdminContactInfo
                    show={showEditContactInfo}
                    onHide={() => setShowEditcontactInfo(false)}
                    triggered={() => setContactInfo({
                        ...contactInfo,
                        triggered: !contactInfo.triggered
                    })
                    }
                    editableData={editableContactInfo}
                    setEditableInfo={() => setEditableContactInfo({})}
                />
            }

            {showAddHeathCategory &&
                <AddEditHealthCategoryModal
                    show={showAddHeathCategory}
                    onHide={() => setShowAddHealthCategory(false)}
                    triggered={() => setHealthCategory({
                        ...healthCategory,
                        triggered: !healthCategory.triggered
                    })
                    }
                    editableData={editableHealthCategory}
                    setEditableHeathCategory={() => setEditableHeathCategory({})}
                />
            }
            {showAddSubHealthCategory &&
                <AddEditSubHealthCategory
                    show={showAddSubHealthCategory}
                    onHide={() => setShowAddSubHealthCategory(false)}
                    triggered={() => setHealthCategory({
                        ...healthCategory,
                        triggered: !healthCategory.triggered
                    })
                    }
                    editableData={editSubHealtCategroy}
                    setEditableSubHeathCategory={() => setEditSubHealthCategory({})}
                />
            }
            {showAddTestimonialModal &&
                <AddTestimonialModal
                    show={showAddTestimonialModal}
                    onHide={() => setShowAddTestimonialModal(false)}
                    triggered={() => setTestimonials({
                        ...testimonials,
                        triggered: !testimonials.triggered
                    })
                    }
                    testimonialItemData={testimonialItemId}
                    setTestimonialItemId={() => setTestimonialItemId({})}
                />
            }
        </>

    );
};
